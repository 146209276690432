// $primary: #a51616;
$primary: #67B298; /* MAIN COLOR */
$secondary: #FFA894; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #FFEAE5;



a {font-family: 'Oregano', cursive;
 font-size: 1em;}

p, ol, ul {font-family: 'Gafata', sans-serif;;
	 	   font-size: 1.5em;}

ol, ul {font-family: 'Gafata', sans-serif;;
	 	   font-size: 1.25em;}

.top-pad {padding-top: 150px !important;}

.top-pad h3 {color: $primary;}


.flash {
	display:none;
}

nav {
	z-index: 1000;
}


.row {margin-left: 0px;
	  margin-right: 0px;}

.navbar-default {background: url('../img/header-nav-bg.png');
				background-repeat: repeat-x;
				border-bottom: 2px solid $secondary;
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(255,255,255,1) 49%, rgba(103,178,152,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 49%,rgba(103,178,152,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(255,255,255,1) 49%,rgba(103,178,152,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#67b298',GradientType=1 ); /* IE6-9 */

				}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 10px;
		display: flex;
		align-items: center;
		color: $blk;
		text-shadow: 1px 1px $secondary;
		outline: none;
		border-right: 1px solid $secondary;


		@media (max-width: 767px) {
		    height: 30px;
			display: inline-block;
			border-right: 0px solid $secondary;
		}

	&:focus, &:visited {
	    	background: transparent;
	    	color: $blk;
}
	
		&:hover {
			color: $wht;
		}
	}
}


.navbar-right {
	margin-top: 45px;
	font-size: 3em;

	@media (max-width: 1024px) {
		margin-top: 25px;
		font-size: 2em;
	}

	@media (max-width: 767px) {
			margin-top: 0px;
			background-color: $wht;
			font-size: 1.85em;
		}

}


.navbar-fixed-top {
	margin: 0px;
}
 
.navbar-toggle {
    margin: 25px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	background-color: $secondary !important;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}



/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 1em;

	a {
		color: $footerLinks;
		font-size: 1em;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}

		p {font-size: 1.35em;}


}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: $primary;
    border-color: $wht;
	   color: $wht;
padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 25px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.btn-download:hover {
    background-color: $secondary;
    color: $wht;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}

/* ========== HEADER ============== */
.header {background-image: url('../img/banner.jpg');
		 background-repeat: no-repeat;
		 background-position: 0% 20%;
		 background-size: cover;
 		 -moz-background-size: cover;
		 -o-background-size: cover;
  		 -webkit-background-size: cover;
		   text-align: center;
		   margin-top: 110px;

	@media (max-width: 1199px) {
		background-position: 0% 20%;
		margin-top: 85px;
	}

	@media (max-width: 1024px) { 
		background-position: 0% 10%;

	}

	@media (max-width: 767px) {
		background-position: 10% 10%;
	}

}

.header-overlay {
  background-color: rgba(31, 31, 31, 0.5);
  padding: 200px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


	@media (min-width:1199px) and (max-width: 1440px) {
			padding: 50px 0px;
	}


	@media (max-width: 1199px) {
			padding: 25px 0px;
	}

    @media (max-width: 1023px) {
			padding: 0px;

	}

	@media (max-width: 768px) {
		padding-bottom: 50px;
		background-color: rgba(31, 31, 31, 0.75);
		
	}



}

.header h1 {color: $wht;
			text-align: center;
			text-shadow: 3px 3px $secondary;

			@media (max-width: 1023px) {
				font-size: 4em;
			}

			@media (max-width: 768px) {
				text-shadow: 1px 1px $secondary;
				font-size: 3.5em !important;
			}
}

.header p {
			  color: $wht;
			  
			@media (max-width: 767px) {
				font-size: 1.5em;
			}
			  
}

.logo {
  width: 100%;
  max-width: 300px;
  margin-left: 10px;
  padding: 5px;


  @media (max-width: 1024px) {
	   	   width: 100%;
			max-width: 217px;
			margin-left: 5px;
			padding: 5px;
  }  

}

  .header img {display: block;
			 margin: 0 auto;
			 width: 100%;
			 max-width: 375px;
			 
			@media (max-width: 1024px) {
				margin-top: 50px;
			}

			@media (max-width: 1024px) {
				margin-top: 25px;
			}
			 
}

/* ========== MINIFORM AREA ============== */

.miniform {
background: rgb(255,255,255); /* Old browsers */
background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%, rgba(103,178,152,1) 100%); /* FF3.6-15 */
background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(103,178,152,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(103,178,152,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#67b298',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */



		@media (max-width: 1024px) {
			padding: 0px;
			text-align: center;
		}

		@media (max-width: 768px) {
			background-position: 20% 10%;
			padding: 0px;
		}


}

.miniform-overlay {
				  padding: 10px 0px 100px 0px;

		@media (max-width: 1199px) {
			padding: 0px;
		}


}

.miniform img {width: 100%;
			   display: block;
			   margin: 0 auto;
			   max-width: 650px;
			   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


			@media (max-width: 1024px) {
				margin-top: 25px;
			}


			   }

.miniform h1 {
			  color: $blk;
			  text-shadow: 2px 2px $secondary;
			  padding: 10px 0px;

			@media (max-width: 767px) {
				font-size: 2.5em;
			}

			}

.miniform p {color: #333;
			 letter-spacing: 1px;
			 line-height: 40px;
			 padding: 10px;
			 
			@media (max-width: 767px) {
				font-size: 1.5em;
			}
}

.container-fluid {padding-right: 0px;
				  padding-left: 0px;}

.col-md-3 {
			
			@media (min-width: 768px) and (max-width: 1024px) {
				width: 99%;
				padding: 15px;
			}
}

/*=========== Upper CTA ============ */

.upper-cta {
background: $secondary;

padding: 10px;

			@media (max-width: 1280px) {
				background-position: 50% 10%;
			}
			
			@media (max-width: 768px) {
				padding: 1px;
			}


}

.upper-cta-overlay {background: rgba(31, 31, 31, 0.19);
				  padding: 10px 10px;
				  border-right: 2px solid $wht;
				  border-top: 2px solid $wht;
				  border-bottom: 2px solid $wht;
				  min-height: 269px;

				  @media (max-width: 1199px) {
					margin: 0px;
					height: 293px;	  
				  }

				  @media (min-width: 992px) and (max-width: 1023px) {
					  margin: 0px !important;
					  height: 293px !important;
					  
				  }

				  @media (max-width: 1024px) {
					  margin: 5px;
					  border: 2px solid $wht;
					  height: auto;
				  }

}

.upper-cta-overlay:first-child {border-left: 2px solid $wht;}

.upper-cta h1 {
			   color: $wht;
			   text-shadow: 2px 2px $blk;
			
			@media (max-width: 767px) {
				font-size: 2.75em;
			}	
			   
			   
			   }

.upper-cta p {letter-spacing: 2px;
			  color: $wht;
			  text-shadow: 1px 1px $blk;
			
			
			@media (max-width:1024px) {
				  font-size: 1.65em;
				  line-height: 40px;
			  }
			
			
			@media (max-width: 767px) {
				font-size: 1.5em;
			}	
}


/* ========== BENEFITS ============== */


.benefits {background: url('../img/lower-banner.jpg');
		   background-repeat: no-repeat;
		   background-position: 50% 10%;
		   background-size: cover;
		   -moz-background-size: cover;
		   -o-background-size: cover;
		   -webkit-background-size: cover;
		   text-align: center;
		   padding: 10px;

		   @media (max-width: 767px) {
			   background-position: 80% 10%;
		   }

}

.benefits-overlay {
  padding: 100px;
  background-color: rgba(31, 31, 31, 0.5);

    @media (max-width: 1199px) {
	  padding-top: 80px;
  }


  @media (max-width: 1024px) {
	  padding: 0px;
	  text-align: center;
	   background-color: rgba(31, 31, 31, 0.75);
  }



}

.benefits h1 {
		      color: $wht;
			  text-shadow: 2px 2px $secondary;

			  @media (max-width: 1024px) {
				  font-size: 3em;
			  }

			@media (min-width: 1024px) and (max-width: 1199px) {
				font-size: 3.5em;
			}
	}

.benefits p {
			 letter-spacing: 1px;
			 line-height: 40px;
			 padding: 10px;
			 text-align: center;
			 color: $wht;
			 
			 
			 @media (max-width: 1024px) {
				color: $wht;
			 }
			 
			 
			 }


.benefits img {width: 100%;
			   max-width: 550px;
			   display: block;
			   margin: 0 auto;
			   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			   margin-bottom: 25px;

			   @media (max-width: 1280px) {
				   display: none;
				}

			@media (max-width: 767px) {
				  max-width: 250px;
  			  }


}

.col-md-6 {width: 50%;

		 @media (max-width: 1440px) {
			 width: 100%;
			 padding: 5px;
		 }
}


/* ========== FEATURES ============== */


.features {padding: 50px 0px;
			@media (max-width: 1024px) {
				padding: 0px;
			}


}

.features h1 {
			  color: $secondary;
			  text-shadow: 2px 2px $primary;
			  text-align: center;

			  @media (max-width: 1024px) {
				  font-size: 3em;
			  }
}

.features h2 {
			  color: $secondary;
			  text-shadow: 2px 2px $primary;
			  
			  @media (max-width: 1024px) {
				  text-align: center;
			  }
			  @media (min-width:1024px) and (max-width: 1199px) {
				  font-size: 2em;
			  }
			  
			  }

.features p {
			 letter-spacing: 1px;
			 line-height: 30px;
			 color: $primary;
			 margin-bottom: 50px;
			 
			@media (max-width: 1024px) {
				  text-align: center;
			  }

			@media (min-width:1024px) and (max-width: 1199px) {
				  font-size: 1.35em;
			  }
}


.features div i {font-size: 200px;
				 color: $secondary;
				 text-shadow: 2px 2px $primary;}



.features img {width: 100%;
			   max-width: 250px;
			   display: block;
			   margin: 0 auto;


			   @media (max-width: 1024px) {
				   max-width: 250px;
			   }
		}


/*============ RECENT MEMBERS ============ */

.recent-members {background: url('../img/lower-banner-2.jpg');
				 background-position: 10% 10%;
				 		 background-size: cover;
 		 -moz-background-size: cover;
		 -o-background-size: cover;
  		 -webkit-background-size: cover;
		   padding: 10px;
				
				@media (max-width: 768px) {
					background-position: 100% 50%;
				}
				
				 }

.recent-members-overlay { padding: 50px 0px;
  background-color: rgba(31, 31, 31, 0.65);
  padding: 15px 0px;

  			@media (max-width: 767px) {
				  padding: 25px 0px;
			  }

}

.recent-members h1 {
			  color: $wht;
			  text-shadow: 2px 2px $secondary;
			  text-align: center;

			  @media (max-width: 1024px) {
				  font-size: 4em;
			  }

			  @media (max-width: 767px) {
				  font-size: 3em;
			  }
}

.recent-members h2 {
			  color: $wht;
			  text-shadow: 2px 2px $secondary;
			  
			  @media (max-width: 1024px) {
				  text-align: center;
				  padding-bottom: 25px;
			  }
			  @media (min-width:1024px) and (max-width: 1199px) {
				  font-size: 2em;
			  }
			  
			  }

.recent-members p {
			 letter-spacing: 1px;
			 line-height: 30px;
			 color: $wht;
margin-bottom: 50px;}

.recent-members img {
	width: 100%;
	max-width: 350px;
	display: block;
	margin: 0 auto;
	margin-bottom: 70px;
}

h1 {font-family: 'Oregano', cursive;
	font-size: 5em;
	
	@media (min-width: 1199px) and (max-width: 1440px) {
		font-size: 4em !important;
	}
	
	
	}

h2 {font-family: 'Oregano', cursive;
	font-size: 4em;}

h3 {font-family: 'Oregano', cursive;
	font-size: 3em;

	color: #fff;
	text-shadow: 2px 2px $blk;
}